import { palette } from '../styles/themes/vad/palette';

export const v1 = 123;
export const v2 = 'https://website/api';
export const v3 = 'https:website.com/search/en/';

// Need to check language keys with sitecore whenever any more language get added in website as of today(en, ar-AE, ch-CN) are correct
export const languageIdList = {
  en: '1',
  'en-GB': '1',
  ar: '2',
  'ar-AE': '2',
  fr: '3',
  de: '4',
  ru: '5',
  jap: '6',
  it: '7',
  zh: '8',
  'zh-CN': '8',
  por: '9',
  nld: '10',
};
export const LOCATION_ID = '1'; // currently fixed location id i.e ABU DHABI for live weather api
export const ISMOBILE = false;
export const HOME_PAGE_ROUTE_NAME = 'Home';
export const SOCIAL_SHARE_ICONS_SCRIPT_URL =
  'https://platform-api.sharethis.com/js/sharethis.js';
export const LOCALSTORAGE_FAVORITE_KEY = 'favorites';
export const LOCALSTORAGE_PRICE_KEY = 'price';
export const EVENT_TYPE = 'event';
export const ATTRIBUTE_TYPE = 'attribute';
export const EVENT_LISTING_TEMPLATE_ID = 'dc24d618-8d10-47d6-be80-cf36ceffec65';
export const AD_EVENT_TEMPLATE_ID = '8311946f-c969-4321-918f-73fab3c88e8d';
export const EVENT_DETAILS_TEMPLATE_ID = '8311946f-c969-4321-918f-73fab3c88e8d';
export const FAVORITE_LISTING_TEMPLATE_ID = '4e825fdb-9496-4325-9e5a-9b406ddf0832';
export const TAG_TEXT = 'tag';
export const LISTING_TYPE = 'listing';
export const COMMON_TYPE = 'common';
export const TAGS = 'Tags';
export const SEARCH_RESULT_TAGS = 'searchResultTags';
export const EVENTS_THEME_COLOR = palette.common.DarkTurquoise;
export const EVENTS_TAG_THEME_COLOR = palette.common.Amarnath();
export const SIMILAR_SEARCH_EVENT_TAG_BACKGROUND_COLOR = palette.common.Amarnath(100);
export const SIMILAR_SEARCH_EVENT_TAG_COLOR = palette.common.Amarnath();
export const LISTING_THEME_COLOR = palette.common.AbuDhabiOrange;
export const CLOSE_ICON_TEXT = 'Close';
export const INFO_ICON_TEXT = 'More Info';
export const SIMILAR_EVENT_DETAILS_PAYLOAD_KEY = 'similarevents';
export const LABEL_404_PAGE = '404Page';
export const ARABIC_LANG_LAYOUT = 'ar';
export const ARABIC_LANG_API_PAYLOAD = 'ar';
export const INSIDE_CHINA_COUNTRY_CODE = 'cn';
export const OUTSIDE_CHINA_COUNTRY_CODE = 'ae';
export const LANGUAGE_CODE = {
  ENGLISH: 'en',
  ARABIC: 'ar',
  CHINESE: 'zh',
  DEFAULT: 'en',
  HEBREW: 'he',
};
export const YOUKU_PLAYER_API_CLIENT_KEY = '0edbfd2e4fc91b72';
export const GOOGLE_DIRECTION_URL = 'https://www.google.ae/maps/dir/';
export const BAIDU_DIRECTION_URL = 'https://api.map.baidu.com/direction';
export const DOMAIN_SUFFIX = {
  CHINA: '.cn',
  ARABIC: '.ae',
};
export const HIGHLIGHTS_KEY = 'Highlights';
export const GOOGLE_MAP_API = 'https://maps.googleapis.com/maps/api/js';
export const REGION_KEY = 'region';
export const BAIDU_MAP_API_URL =
  'https://api.map.baidu.com/api?v=3.0&ak={apiKey}&callback=initBaiduMap';
export const TICKET_COMPONENT_ID = 'ticket123'; // Its alphanumeric to avoid any conflict in future
export const TICKETED_EVENT_TEXT = 'ticketed';
export const MARKETING_MESSAGE_INACTIVE_TILL =
  'MARKETING_MESSAGE_INACTIVE_TILL';
export const MARKETING_MESSAGE_HIDDEN = 'MARKETING_MESSAGE_HIDDEN';

export const availableTemperatures = ['C', 'F'];
export const defaultTemperature = 'C';
export const BAIDU_API_KEY = 'baiduApiKey';
export const GOOGLE_API_KEY = 'googleApiKey';

export const DETAILS_TEMPLATE_IDS_ARRAY = [
  '59e74234-0a24-4f30-96dc-2618141cdefb',
  'f72c2e2c-fc71-4c45-bed7-5de8e0a827c7',
  'd6a2d240-a981-469b-9669-f0144f5c25b7',
  'a27b92ca-fe89-4e28-8a34-01acc6ccabe5',
  'd8822dd9-3963-4250-ba05-bb700723fd53',
  '4f1c45f1-aab7-447e-9b7d-1a516e4351d6',
  '8311946f-c969-4321-918f-73fab3c88e8d',
  'db7230c8-7175-42e9-b2cd-7726a966a72b',
]

export const FAQ_TEMPLATE_ID = '2f34465a-4cea-4a86-bae0-f3c16afa5bf4'

export const MAP_3D_TEMPLATE_ID = '92baecc9-2d31-4835-a049-6ec1d34f5a71'
export const MAP_TYPE_MEDIA_MONK = 'kiosk-mode'
export const EXPLORE_MEDIA_MONK_MAP = 'ExploreMediaMonkMap'

export const ARABIC_MONTHS = ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'];
