import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  checkboxType,
  calendarType,
  dropdownType,
  dateRangeKeys,
} from '../global/containers/common/Filters/constants';
import { getLocalStorage } from '../utils/localStorage';
import { getDictionaryText } from '../global/utils/getDictionaryText';

/**
 *  Returns wheather filter object has no value in present fields
 */
export const isFilterObjectEmpty = obj => {
  let isEmpty = true;
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      isEmpty = false;
    } else if (!Array.isArray(obj[key]) && obj[key]) {
      isEmpty = false;
    }
  });

  return isEmpty;
};

export const isFilterObjectEmptyWithoutGTMValues = obj => {
  const gtm = getDictionaryText('nonremoveablefilters')?.split(',') || [];
  let isEmpty = true;
  Object.keys(obj).forEach(key => {
    const conditions = gtm.includes(key);
    if (Array.isArray(obj[key]) && obj[key].length > 0 && !conditions) {
      isEmpty = false;
    } else if (!Array.isArray(obj[key]) && obj[key] && !conditions) {
      isEmpty = false;
    }
  });

  return isEmpty;
};

/**
 *  Returns count of Filters
 */
export const getFilterCount = filterData => {
  let filterCount = 0;
  const gtm = getDictionaryText('nonremoveablefilters')?.split(',') || [];
  if (isEmpty(filterData) || isFilterObjectEmpty(filterData)) {
    return filterCount;
  } else {
    Object.keys(filterData).forEach(key => {
      const conditions = gtm.includes(key);
      if (
        Array.isArray(filterData[key]) &&
        filterData[key].length > 0 &&
        !conditions
      ) {
        filterCount += filterData[key].length;
      } else if (
        !Array.isArray(filterData[key]) &&
        filterData[key] &&
        !conditions
      ) {
        filterCount += 1;
      }
    });
    return filterCount;
  }
};

export const extractFiltersFromFiltersData = filtersData => {
  let selectedFilters = {};
  for (let index in filtersData) {
    let data = filtersData[index];
    if (data.type === checkboxType || data.type === 'gtm') {
      selectedFilters[data.fieldName.value] = [];
      for (let key in data.listItems) {
        let fields = data.listItems[key].fields;
        if (fields.selected && fields.selected.value) {
          selectedFilters[data.fieldName.value].push(fields.key.value);
        }
      }
    } else if (data.type === calendarType) {
      if (data.startDate && data.startDate.value) {
        selectedFilters.startDate = moment(
          data.startDate.value,
          'MM-DD-YYYY'
        ).format('MM-DD-YYYY');
      }
      if (data.endDate && data.endDate.value) {
        selectedFilters.endDate = moment(
          data.endDate.value,
          'MM-DD-YYYY'
        ).format('MM-DD-YYYY');
      }
    } else {
      if (data.selectedValue.value !== '') {
        selectedFilters[data.fieldName.value] = data.selectedValue.value;
      } else {
        selectedFilters[data.fieldName.value] = '';
      }
    }
  }

  return selectedFilters;
};

/**
 *  Returns keys from dictionary
 */
export const getHelperKeysFromDictionary = (dictionary, dictionaryKeys) => {
  let helperKeys = {};
  Object.keys(dictionaryKeys).forEach(key => {
    if (dictionary(key) !== key) {
      helperKeys[key] = {
        value: dictionary(key),
      };
    }
  });

  return helperKeys;
};

export const getPathName = url => {
  if (url) {
    return new URL(url).pathname;
  }
  return '';
};

// function used for replacing dictionary labels
// I/P -> 'Bases on {0} reviews by {1}, replaceList -[2496, 'Eric']
// O/p -> Based on 2496 reviews by Eric
export const replaceKeysLabelText = (field, replaceList) => {
  const newField = { ...field };
  if (newField && newField.value && replaceList.length) {
    replaceList.forEach((item, index) => {
      newField.value = newField.value.replace(`{${index}}`, item);
    });
  }
  return newField;
};

export const createPhoneLink = number => {
  return number && `tel:${number}`;
};

// Todo : Would change it to regex later
export const getDomainfromEmail = email => {
  let domain = '';
  if (email) {
    domain = email.split('@')[1].split('.')[0];
  }
  return domain;
};

/**
 *  Returns object with non empty key and value
 */
export const removeEmpty = obj => {
  Object.keys(obj).forEach(
    key => (obj[key] == null || obj[key] === '') && delete obj[key]
  );

  return obj;
};

/*
 * Update filters data received from solr API response
 */
export const updateFilterData = (solRresponse, sitecoreFilters, dictionary) => {
  const { facetResult } = solRresponse;
  let updatedFilterData = [];
  sitecoreFilters.forEach(filter => {
    let filterData = {};
    filterData.fieldLabel = {
      value: filter.fields.taxonomyValue.value,
    };
    filterData.totalResultCount = 0;
    filterData.fieldName = {
      value: filter.fields.taxonomyKey.value,
    };
    filterData.listItems = [];
    filterData.selectedValue = {
      value: '',
    };
    if (dictionary) {
      filterData.type = dictionary(filter.fields.taxonomyKey.value);
    }
    Object.keys(facetResult).forEach(resultKey => {
      if (resultKey.includes(filter.fields.taxonomyKey.value)) {
        if (
          facetResult[resultKey] &&
          (facetResult[resultKey].length > 0 ||
            filter.fields.taxonomyKey.value === dateRangeKeys.dateRangeField)
        ) {
          if (
            filter.fields.taxonomyKey.value === dateRangeKeys.dateRangeField
          ) {
            filterData.startDate = {
              value: '',
            };
            filterData.endDate = {
              value: '',
            };
            filterData.startDateName = {
              value: dateRangeKeys.startDate,
            };
            filterData.endDateName = {
              value: dateRangeKeys.endDate,
            };
            filterData.monthsShown = 1;
            filterData.eventCalender = true;
          }
          facetResult[resultKey].forEach(result => {
            if (result.count >= 1) {
              let item = {
                fields: {
                  displayText: {
                    value: result.value,
                  },
                  key: {
                    value: result.key,
                  },
                  selected: {
                    value: '',
                  },
                  count: {
                    value: result.count,
                  },
                },
              };
              filterData.totalResultCount += result.count;
              filterData.listItems.push(item);
            }
          });
        } else if (filterData.type === dropdownType) {
          Object.keys(solRresponse).forEach(responseKey => {
            if (responseKey.includes(filter.fields.taxonomyKey.value)) {
              solRresponse[responseKey].forEach(item => {
                let groupedListItem = {
                  groupheading: {
                    key: item.key,
                    value: item.value,
                  },
                  groupItems: [],
                };
                item.child.forEach(result => {
                  let fields = {
                    displayText: {
                      value: result.value,
                    },
                    key: {
                      value: result.key,
                    },
                    parent: {
                      value: item.parent,
                    },
                  };

                  groupedListItem.groupItems.push(fields);
                });
                filterData.listItems.push(groupedListItem);
              });
            }
          });
        }
      }
    });
    if (
      (filterData.listItems && filterData.listItems.length > 0) ||
      filterData.type === calendarType
    ) {
      updatedFilterData.push(filterData);
    }
  });
  return updatedFilterData;
};

/* Returns current time in a specific timezone i.e +4 for AbuDhabi */
export const getTimeFromTimezone = (offset = '+4', offsetDay = 0) => {
  const currentDate = new Date(); //get current time to calculate the UTC time
  let utc = currentDate.getTime() + currentDate.getTimezoneOffset() * 60000; //calculates the UTC time
  let nd = new Date(utc + 3600000 * offset); // get the current timezone with the offset
  if (offsetDay !== 0) {
    nd.setDate(nd.getDate() + offsetDay);
  }
  return nd.toString();
};

/*
 * validateUrl returns whether the given URL is valid or not
 */
export const validateUrl = value => {
  // eslint-disable-next-line max-len
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
};
// check If Itinerary Exists
export const isItineraryExist = () => {
  let itineraryList = JSON.parse(getLocalStorage('itineraryList') || '[]');
  return Array.isArray(itineraryList) && itineraryList?.length > 0;
};

export const updateURLParameter = (param, value) => {
  var url = new URL(window.location.href);
  url.searchParams.set(param, value);
  window.history.replaceState({}, '', url);
};

// MM-DD-YYYY
export function formatDate(date) {
  const month = date.getMonth() + 1; // Months are zero-based
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}-${year}`;
}

// Get last Monday from today (or this Date if today is Monday), Format: MM-DD-YYYY
export function getLastMonday(date) {
  const dayOfWeek = date.getDay(); // 1 means Monday
  const daysToLastMonday = dayOfWeek === 1 ? 0 : dayOfWeek - 1;
  const lastMonday = new Date(date);
  lastMonday.setDate(date.getDate() - daysToLastMonday);
  return formatDate(lastMonday);
}

// Get next Sunday from today (or this Date if today is Sunday), Format: MM-DD-YYYY
export function getNextSunday(date) {
  const dayOfWeek = date.getDay(); // 0 means Sunday
  const daysToNextSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
  const nextSunday = new Date(date);
  nextSunday.setDate(date.getDate() + daysToNextSunday);
  return formatDate(nextSunday);
}

export function getFirstDayOfMonth(date) {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  return formatDate(firstDay);
}

// Get last Day of the month (or this Date if today is last day), Format: MM-DD-YYYY
export function getLastDayOfMonth(date) {
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return formatDate(lastDay);
}

export const getStartAndEndDate = value => {
  const currentDate = new Date();
  const today = formatDate(currentDate);
  switch (value) {
    case 'this_week':
      const nextSunday = getNextSunday(currentDate);
      return { startDate: today, endDate: nextSunday };

    case 'this_month':
      const lastDayOfMonth = getLastDayOfMonth(currentDate);
      return { startDate: today, endDate: lastDayOfMonth };

    case 'next_month':
      const nextMonthDate = new Date(currentDate);
      nextMonthDate.setMonth(currentDate.getMonth() + 1);
      const firstDayOfNextMonth = getFirstDayOfMonth(nextMonthDate);
      const lastDayOfNextMonth = getLastDayOfMonth(nextMonthDate);

      return { startDate: firstDayOfNextMonth, endDate: lastDayOfNextMonth };

    default:
      return { startDate: today, endDate: nextSunday };
  }
};
